// extracted by mini-css-extract-plugin
export var lbContainerOuter = "v_rB";
export var lbContainerInner = "v_rC";
export var movingForwards = "v_rD";
export var movingForwardsOther = "v_rF";
export var movingBackwards = "v_rG";
export var movingBackwardsOther = "v_rH";
export var lbImage = "v_rJ";
export var lbOtherImage = "v_rK";
export var prevButton = "v_rL";
export var nextButton = "v_rM";
export var closing = "v_rN";
export var appear = "v_rP";