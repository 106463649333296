// extracted by mini-css-extract-plugin
export var articleWrapper = "p_qq";
export var articleText = "p_qr f_dK f_cH";
export var header = "p_qs f_bc f_L";
export var headerImageWrapper = "p_qt f_bs f_bd f_bn f_bm f_bj f_bp f_by f_b4";
export var headerGradient = "p_qv f_bs f_bd f_bn f_bm f_bj f_bp";
export var headerGradientOverlay = "p_qw f_bs f_bd f_bn f_bm f_bj f_bp";
export var headerContentWrapper = "p_qx f_bP f_b2 f_bS f_bX f_bz f_bc";
export var headerContentWrapperAlt = "p_qy f_bP f_b2 f_bS f_bX f_bz f_bc";
export var contentWrapper = "p_r f_L";
export var dateTag = "p_qz f_ct f_cG f_dF f_L";
export var icon1 = "p_qB f_c2";
export var icon2 = "p_qC f_c2 f_cX";
export var tagBtn = "p_qD f_cf f_cS f_dD f_dQ";
export var headerText = "p_qF f_L f_ct f_cH f_b8";
export var center = "p_qG f_dL";
export var videoIframeStyle = "p_qc f_fj f_L f_W f_bN f_cf f_4";
export var articleImageWrapper = "p_qH f_cH f_bc";
export var articleImageWrapperIcon = "p_qJ f_cH f_bc";
export var articleRow = "p_qK f_bS f_bt";
export var quoteWrapper = "p_g2 f_bR f_b2 f_cH";
export var quoteBar = "p_qj f_W";
export var quoteText = "p_qk";
export var authorBox = "p_qL f_L";
export var authorRow = "p_qM f_bS f_b8 f_cN";
export var separator = "p_qN f_L";
export var line = "p_fx f_L f_fx f_db f_cN";
export var authorImage = "p_lH f_cm f_bc f_L f_cJ";
export var authorText = "p_qP f_cw f_cJ";
export var masonryImageWrapper = "p_qm";
export var bottomSeparator = "p_qQ f_L f_cN";
export var linksWrapper = "p_qR f_cw f_dK";
export var comments = "p_qS f_bS f_cN";
export var sharing = "p_qT f_bP f_bS f_bX";
export var shareText = "p_qV f_L f_dL";
export var icon = "p_qW";
export var text = "p_qX";
export var SubtitleSmall = "p_qY";
export var SubtitleNormal = "p_qZ";
export var SubtitleLarge = "p_q0";